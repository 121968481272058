import React from "react"
import { Header, Icon, Breadcrumb } from "semantic-ui-react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const OverviewsPage = () => {
  return <Layout>
    <SEO title="Overviews" />
    <div style={{ marginLeft: "10px", marginTop: "50px", marginBottom: "50px" }}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          Home
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Overview</Breadcrumb.Section>
      </Breadcrumb>

      <Header as="h1" dividing>
        <Icon name="lightbulb" />
        <Header.Content>
          Overview Pages
          <Header.Subheader>High-Level View of Our Data</Header.Subheader>
        </Header.Content>
      </Header>
      <p>
        The following pages provide different perspectives on our collection:
      </p>
      <ul>
        <li><Link to='/overview/refutations'>Refutations</Link></li>
        <li><Link to='/overview/symptoms'>Symptoms</Link></li>
        <li><Link to='/overview/references'>References</Link></li>
        <li><Link to='/overview/textbooks'>Textbooks</Link></li>
        <li><Link to='/overview/notionalMachines'>Notional Machines</Link></li>
        <li><Link to='/conceptMap'>Concept Map</Link></li>
        <li><Link to='/conceptMatrix'>Concept Matrix</Link></li>
      </ul>
      <p>
        Conceptual Self-Check:
      </p>
      <ul>
        <li><Link to='/selfCheck'>Everything</Link></li>
        <li><Link to='/selfCheck?pl=Java'>Java</Link></li>
        <li><Link to='/selfCheck?pl=JavaScript'>JavaScript</Link></li>
        <li><Link to='/selfCheck?pl=Python'>Python</Link></li>
        <li><Link to='/selfCheck?concepts=Expression'>Expressions</Link></li>
        <li><Link to='/selfCheck?pl=Java&concepts=Expression'>Expressions in Java</Link></li>
        <li><Link to='/selfCheck?pl=Java&concepts=Expression,Literal'>Expressions and Literals in Java</Link></li>
        <li><Link to='/selfCheck?textbook=ofj6/4'>OFJ6, Chapter 4</Link></li>
      </ul>
      <p>
        Other relevant pages:
      </p>
      <ul>
        <li><Link to='/all/misconceptions/'>All Misconceptions</Link></li>
        <li><Link to='/public/misconceptions/'>Public Misconceptions</Link></li>
        <li><Link to='/draft/misconceptions/'>Draft Misconceptions</Link></li>
      </ul>
    </div>
  </Layout>;
}

export default OverviewsPage
